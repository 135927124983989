import {ILocalStore, openStore} from './adapter';
import {ShortAccount, } from "../../model/short-account";

export interface IApplicationStoredModel {
  accounts: ShortAccount[];
}

const OBJECT_NAME = 'hub3:application';
const VERSION = 1;

function migrator(dbRequest: IDBOpenDBRequest, versions: IDBVersionChangeEvent) {
  switch (versions.oldVersion) {
    case 0:
      dbRequest.result.createObjectStore('accounts', {keyPath: 'userId'});
  }
}

export function getApplicationStorage() {
  return openStore<IApplicationStoredModel>(OBJECT_NAME, VERSION, migrator);
}

export type TIApplicationStore = ILocalStore<IApplicationStoredModel>;
