import {
    BloodPressureIcon,
    Carbo,
    Cholesterol,
    EkgIcon,
    Glukoze,
    Go,
    Hemoglobin,
    Insulin,
    Lungs,
    Pulse,
    Sleep,
    SpO2,
    Statoskop,
    Temperature,
    Urine,
    WeightIcon,
} from "../assets";
import {TypeMeasurements} from "../pages/all-measurements/all-measurements.model";

export const TYPES_MEASUREMENTS: TypeMeasurements[] = [
    {
        code: 'blood_pressure',
        label: 'Артериальное давление',
        unitType: 'мм рт. ст.',
        maxAxis: 300,
        icon: <BloodPressureIcon className="types-measurements__icon"/>
    },
    {
        code: 'pulse',
        label: 'Пульс',
        unitType: 'уд/мин',
        maxAxis: 200,
        icon: <Pulse className="types-measurements__icon"/>
    },
    {
        code: 'hemoglobin',
        label: 'Гемоглобин',
        unitType: 'ммоль/л',
        maxAxis: 200,
        icon: <Hemoglobin className="types-measurements__icon"/>
    },
    {
        code: 'cholesterol',
        label: 'Холестерин',
        maxAxis: 200,
        unitType: 'ммоль/л',
        icon: <Cholesterol className="types-measurements__icon"/>
    },
    {
        code: 'glucose',
        label: 'Уровень глюкозы',
        unitType: 'ммоль/л',
        maxAxis: 35,
        icon: <Glukoze className="types-measurements__icon"/>
    },
    {
        code: 'insulin',
        label: 'Инсулин',
        maxAxis: 100,
        unitType: 'ЕД',
        icon: <Insulin className="types-measurements__icon"/>
    },
    {
        code: 'carbs',
        label: 'Углеводы',
        unitType: 'ХЕ',
        maxAxis: 30,
        icon: <Carbo className="types-measurements__icon"/>
    },
    {
        code: 'temperature',
        label: 'Температура',
        unitType: 'С°',
        maxAxis: 50,
        icon: <Temperature className="types-measurements__icon"/>
    },
    {
        code: 'weight',
        label: 'Вес',
        unitType: 'кг',
        maxAxis: 150,
        icon: <WeightIcon className="types-measurements__icon"/>
    },
    {
        code: 'spo2',
        label: 'Сатурация',
        unitType: '%',
        maxAxis: 100,
        icon: <SpO2 className="types-measurements__icon"/>
    },
    {
        code: 'stethoscope',
        label: 'Аускультация',
        unitType: "мм рт. ст.",
        maxAxis: 150,
        icon: <Statoskop className="types-measurements__icon"/>
    },
    {
        code: 'steps',
        label: 'Физическая активность',
        unitType: "Шаги",
        maxAxis: 10000,
        icon: <Go className="types-measurements__icon"/>
    },
    {
        code: 'sleep',
        label: 'Сон',
        unitType: "ч.",
        maxAxis: 12,
        icon: <Sleep className="types-measurements__icon"/>
    },
    {
        code: 'spirometry',
        label: 'Спирометрия',
        maxAxis: 16,
        unitType: "л",
        icon: <Lungs className="types-measurements__icon"/>
    },
    {
        code: 'urine',
        label: 'Моча',
        unitType: "мкмоль/л",
        icon: <Urine className="types-measurements__icon"/>
    },
    {
    code: 'ecg',
    label: 'ЭКГ',
    unitType: "",
    icon: <EkgIcon className="types-measurements__icon"/>
  }
]