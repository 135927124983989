import {ajax} from "rxjs/ajax";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Instrumentation, TypeMeasurements} from "../pages/all-measurements/all-measurements.model";
import {INSTRUMENTATION} from "../constants/instrumentation";
import {TYPES_MEASUREMENTS} from "../constants/types-measurements";
import {AuthService} from "./auth-service";

interface PermissionPages {
  page: string;
  isNavigation: boolean;
  accessIsAllowedEmptyUser: boolean;
  name: string;
  availableRole: string;
}

interface Permissions {
  permissionPages: PermissionPages[];
}

interface AvailableRoles {
  currenRole: string;
  availableRole: string[];
}

export interface Config {
  projectName: string;
  permissions: Permissions;
  availableInstrumentation: string[];
  availableTypeMeasurement: string[];
  availableLimit: string[];
  availableRoles: AvailableRoles[];
  isHaveInvite: boolean;
  isMapPatient: boolean;
  isShowGraphicsPatient: boolean;
}

export function getConfig(): Observable<Config> {
  return ajax<Config>({url: '/conf/config.json', responseType: 'json'}).pipe(map(resp => resp.response))
}

export class ConfigStore {
  availableInstrumentation: Instrumentation[] = []
  availableTypeMeasurement: TypeMeasurements[] = []

  constructor(public config: Config, protected authService: AuthService) {
    this.availableInstrumentation = INSTRUMENTATION.filter(item => this.config.availableInstrumentation.includes(item.code));
    this.availableTypeMeasurement = TYPES_MEASUREMENTS.filter(item => this.config.availableTypeMeasurement.includes(item.code));
  }

  getInstrumentationFunction(code: string): Instrumentation{
    return this.availableInstrumentation.find(item => item.code === code) || {
      code: '',
      typeMeasurements: [],
      label: 'Устройство не найдено!'
    }
  }

  getTypeMeasurementsFunction(code: string): TypeMeasurements{
    return this.availableTypeMeasurement.find(item => item.code === code) || {
      code: '',
      unitType: '',
      label: 'Тип измерения не найден!'
    }
  }
}
