import {
    Ekg,
    FitnessBraclet,
    Fonedoscop,
    Glucometr,
    Manual,
    Pulseocsimetr,
    Spirometr,
    Termometr,
    Tonometr,
    UrineAnalisis,
    Weights
} from "../assets";
import GlucometrSrc from '../assets/images/glucometr.svg';
import {Instrumentation} from "../pages/all-measurements/all-measurements.model";

export const INSTRUMENTATION: Instrumentation[] = [
    {
        code: 'manual',
        label: 'В ручную',
        typeMeasurements: ['insulin'],
        icon: <Manual/>,
    },
    {
        code: 'smartwatch',
        label: 'Фитнес-браслет',
        typeMeasurements: ['steps', 'pulse', 'sleep'],
        icon: <FitnessBraclet/>,
    },
    {
        code: 'thermometer',
        label: 'Термометр',
        typeMeasurements: ['temperature'],
        icon: <Termometr/>,
    },
    {
        code: 'tonometer',
        label: 'Тонометр',
        typeMeasurements: ['blood_pressure', 'pulse'],
        icon: <Tonometr/>,
    },
    {
        code: 'glucosemeter',
        label: 'Глюкометр',
        typeMeasurements: ['glucose'],
        icon: <Glucometr/>,
        iconSrc: GlucometrSrc
    },
    {
        code: 'pulseoxymeter',
        label: 'Пульсоксиметр',
        typeMeasurements: ['pulse'],
        icon: <Pulseocsimetr/>
    },
    {
        code: 'scales',
        label: 'Весы',
        typeMeasurements: ['weight'],
        icon: <Weights/>
    },
    {
    code: 'stethoscope',
    label: 'Цифровой фонендоскоп',
    typeMeasurements: ['stethoscope'],
    icon: <Fonedoscop/>
  },
  {
    code: 'lung-tester',
    label: 'Спирометр',
    typeMeasurements: ['spirometry'],
    icon: <Spirometr/>
  },
  {
    code: 'urine-analyzer',
    label: 'Анализ мочи',
    typeMeasurements: ['urine'],
    icon: <UrineAnalisis/>
  },
  {
    code: 'electrocardiograph',
    label: 'ЭКГ',
    typeMeasurements: ['ecg','ecg_new'],
    icon: <Ekg/>
  }
]