import {forkJoin, Observable} from 'rxjs';
import {fromIdb, fromIdbCursor} from '../../utils/observable';
import {map} from 'rxjs/operators';

type TMigrator = (dbRequest: IDBOpenDBRequest, versions: IDBVersionChangeEvent) => (void | Observable<unknown>);

export type TSchema<S extends object> = {
  [K in keyof S]-?: S[K] extends object ? S[K] : never;
}[keyof S];

export type TSchemaKeys<S extends object> = {
  [K in keyof S]-?: S[K] extends object ? K extends string ? K : never : never;
}[keyof S];

export type TSchemaTable<S extends object, K extends TSchemaKeys<S>> = S[K];

export type TSchemaTableKeys<S extends object, Keys extends keyof S> = {
  [K in Keys]-?: S[K] extends object ? K extends string ? K : never : never;
}[Keys];

export type TSchemaArrayKeys<S extends object> = {
  [K in keyof S]-?: S[K] extends any[] ? K extends string ? K : never : never;
}[keyof S];

export type TSchemaMapKeys<S extends object, F = Omit<S, TSchemaArrayKeys<S>>> = {
  [K in keyof F]-?: F[K] extends object ? K extends string ? K : never : never;
}[keyof F];

export type ExtractTableItemType<S extends object, T extends keyof S, K extends (keyof S[T]) | never> = S[T] extends (infer A)[]
  ? A
  : S[T] extends object
    ? S[T][K]
    : never;

export type ExtractArraySchemaType<S extends object, K extends keyof S> = S[K] extends (infer A)[] ? A : never;

export type ExtractMapSchemaType<S extends object, K extends TSchemaMapKeys<S>, F extends keyof S[K]> = S[K][F];

export interface ILocalStore<S extends object> {

  get<T extends TSchemaArrayKeys<S>>(table: T, key: TSchemaTableKeys<S, T>): Observable<ExtractArraySchemaType<S, T>>;

  get<T extends TSchemaMapKeys<S>, K extends keyof S[T]>(table: T, key: K): Observable<ExtractMapSchemaType<S, T, K>>;

  getAll<T extends TSchemaKeys<S>>(table: T): Observable<TSchemaTable<S, T>>;

  export(): Observable<S>;

  set<T extends TSchemaArrayKeys<S>>(table: T, value: ExtractArraySchemaType<S, T>): void;

  set<T extends TSchemaMapKeys<S>, F extends keyof S[T]>(table: T, value: ExtractMapSchemaType<S, T, F>, key: F): void;

  setAll<T extends TSchemaMapKeys<S>>(table: T, values: S[T]): void;

  setAll<T extends TSchemaArrayKeys<S>>(table: T, values: ExtractArraySchemaType<S, T>[]): void;

  delete<T extends TSchemaKeys<S>>(table: T, key: IDBValidKey): void;
}

export interface ILocalStoreUpdater<T extends object> {

  onUpgradeNeeded(dbRequest: IDBOpenDBRequest, versions: IDBVersionChangeEvent): void;

  success(storage: ILocalStore<T>): void;

  error(dbRequest: IDBOpenDBRequest): void;
}

class LocalStore<S extends object> implements ILocalStore<S> {

  protected dbRequest;

  constructor(protected readonly dbName: string, version: number, updater: ILocalStoreUpdater<S>) {
    this.dbRequest = indexedDB.open(dbName, version);
    this.dbRequest.onupgradeneeded = (versions) => updater.onUpgradeNeeded(this.dbRequest, versions);
    this.dbRequest.onsuccess = () => updater.success(this);
    this.dbRequest.onerror = () => updater.error(this.dbRequest);
  }

  transaction(mode: IDBTransactionMode, tables?: string | string[]) {
    return this.dbRequest.result.transaction(tables || this.dbRequest.result.objectStoreNames, mode);
  }

  get<T extends keyof S>(table: T, key: keyof S[T]) {
    let request = this.dbRequest.result
      .transaction(table as string, 'readonly')
      .objectStore(table as string)
      .get(key as string);

    return fromIdb(request);
  }

  getAll<T extends TSchemaKeys<S>>(table: T) {
    let transaction = this.dbRequest.result
      .transaction(table as string, 'readonly');

    return this.getAllWithTransaction(transaction, table) as any;
  }

  export(): Observable<S> {
    let transaction = this.transaction('readonly');
    let readers = Array.from(this.dbRequest.result.objectStoreNames)
      .reduce<any>((obj, table) => {
        obj[table] = this.getAllWithTransaction(transaction, table as any);
        return obj;
      }, {}) as Observable<{
      [K in keyof S]: Observable<S[K]>
    }>

    return forkJoin(readers) as Observable<S>;
  }

  set<T extends TSchemaKeys<S>, K = T extends TSchemaMapKeys<S> ? TSchemaTableKeys<S, T> : undefined>(table: T, value: any, key?: K) {
    this.dbRequest.result.transaction(table, 'readwrite').objectStore(table).put(value, key as unknown as string);
  }

  setAll<T extends TSchemaKeys<S>>(table: T, values: ExtractArraySchemaType<S, T>[] | TSchemaTable<S, T>) {
    let transaction = this.transaction('readwrite', table).objectStore(table);

    if (Array.isArray(values)) {
      for (let it of values) {
        transaction.put(it);
      }
    } else if (typeof values === 'object' && values !== null) {
      Object.entries(values)
        .forEach(([key, value]) => {
          transaction.put(value, key);
        })
    }
  }

  delete(table: TSchemaKeys<S>, key: IDBValidKey) {
    this.dbRequest.result.transaction(table, 'readwrite').objectStore(table).delete(key);
  }

  protected getAllWithTransaction<T extends TSchemaKeys<S>>(transaction: IDBTransaction, table: T) {
    let store = transaction.objectStore(table);
    let request;

    if (store.keyPath) {
      request = fromIdb(store.getAll());
    } else {
      request = fromIdbCursor(transaction, store)
        .pipe(
          map(Object.fromEntries)
        ) as any;
    }

    return request;
  }
}

export function openStore<T extends {}>(dbName: string, version: number, migrator: TMigrator): Observable<ILocalStore<T>> {
  return new Observable<ILocalStore<T>>(observer => {
    new LocalStore(dbName, version, {
      onUpgradeNeeded(req, version) {
        let updater$ = migrator(req, version);
        if (!updater$) {

        }
      },
      success(storage: ILocalStore<T>) {
        observer.next(storage);
        observer.complete();
      },
      error(dbRequest: IDBOpenDBRequest) {
        observer.error(dbRequest);
      }
    });
  })
}
