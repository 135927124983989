import {IObservableArray} from "mobx";

import {action, makeObservable, observable} from 'mobx';
import {firstValueFrom, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {OrganizationDto, Telemed} from '../conectors/telemed';
import {ShortAccount} from "../model/short-account";
import {AccountService, accountServiceFactory, TAccountServiceFactory} from "./account-service";
import {TIApplicationStore} from "./local-store";

export class AuthService {

  public readonly accounts: IObservableArray<ShortAccount>;
  public activeAccountService: AccountService | null;
  public allOrganizations: OrganizationDto[] = [];

  protected readonly factory: TAccountServiceFactory;

  constructor(protected telemed: Telemed,
              protected applicationStore: TIApplicationStore,
              accounts: ShortAccount[],
              public activeAccount: ShortAccount | null) {
    this.accounts = observable(accounts, {deep: false});
    this.factory = accountServiceFactory(telemed);
    this.activeAccountService = activeAccount ? this.factory(activeAccount) : null;

    makeObservable(this, {
      activeAccount: observable.ref,
      allOrganizations: observable.ref,
      chooseAccount: action.bound,
      onSuccessLogin: action.bound,
      updateAllOrganizations: action.bound,
      getOrganization: action.bound,
    });
    this.telemed.setUserContext(this.activeAccount);
    this.updateAllOrganizations();
  }

  login(fields: Record<string, string>): Observable<ShortAccount> {
    return this.loginAccount(fields)
      .pipe(
        tap(this.onSuccessLogin)
      )
  }

  updateAllOrganizations(){
    if(this.activeAccount){
      this.telemed.getOrganizations()
        .pipe(
          tap( resp => this.allOrganizations = resp.value)
        )
        .subscribe()
    }
  }

  getOrganization(orgId: number | null): OrganizationDto | undefined{
    return this.allOrganizations.find(item => item.id === orgId)
  }

  onSuccessLogin(account: ShortAccount) {
    this.telemed.setUserContext(account);
    this.telemed.getOrganizations()
      .pipe(map(
        resp => {
          this.allOrganizations = resp.value
          let current = this.accounts.find(it => it.userId === account.userId);
          if (current) {
            Object.assign(current, account);
          } else {
            this.accounts.unshift(account);
            this.chooseAccount(account);

            try {
              this.applicationStore.set('accounts', account);
            } catch (e) {
            }
          }
        }
      ))
      .subscribe()
  }

  chooseAccount(account: ShortAccount | null) {
    if (this.activeAccount === account) {
      return;
    }

    this.activeAccount = account;

    if (account) {
      this.accounts.clear();
      this.accounts.unshift(account);
      this.telemed.setUserContext(account);
      this.activeAccountService = account ? this.factory(account) : null;

      try {
        this.applicationStore.set('accounts', account);
      } catch (e) {
      }
    }
  }

  logout(account: ShortAccount, skipServerLogout = false, useNextAccount = true): void {
    this.accounts.clear();
    this.applicationStore.delete('accounts', account.userId);
    this.chooseAccount(this.accounts[0] && useNextAccount ? this.accounts[0] : null);
    localStorage.clear();


    if (!skipServerLogout) {
      void firstValueFrom(
        this.telemed.logout()
          .pipe(
            map(() => this.telemed.setUserContext(null)),
            catchError(() => of(undefined))
          )
      );
    } else {
      this.telemed.setUserContext(null)
    }

  }

  protected loginAccount(fields: Record<string, string>): Observable<ShortAccount> {
    return this.telemed.login({
      email: fields.email,
      password: fields.password
    })
      .pipe(
        map(resp => ShortAccount.fromObject({
            userId: resp.userId,
            role: resp.role,
            accessToken: resp.accessToken,
            firstName: resp.firstName,
            lastName: resp.lastName,
            email: resp.email,
            orgId: resp.orgId,
            organization: undefined,
            avatar: resp.avatar,
            middleName: resp.middleName,
          }
        ))
      );
  }

}


