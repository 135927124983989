import {ajax, AjaxConfig} from 'rxjs/ajax';
import {catchError, mergeMap, take, tap} from 'rxjs/operators';
import {fromEvent, ObservableInput, throwError} from 'rxjs';
import {GlobalState} from '../global';

export function fetch$(request: AjaxConfig) {
  return ajax(request)
    .pipe(catchOffline())
}

function catchOffline<T, O extends ObservableInput<any>>() {
  return catchError<T, O>((e, $) => {
    if (!navigator.onLine) {
      GlobalState.waitingInternetConnection.enable();
      return fromEvent(window, 'online')
        .pipe(
          take(1),
          tap(GlobalState.waitingInternetConnection.disable),
          mergeMap(() => $)
        ) as unknown as O;
    } else {
      return throwError(e) as unknown as O;
    }
  })
}
