import {Observable} from "rxjs";
import {ShortAccount} from "../../model/short-account";
import buildQuery from 'odata-query'
import {INotifier} from "../../providers/notifications/notifications.context";
import {AuthService} from "../../services/auth-service";
import {AjaxResponse} from "rxjs/ajax";
import {
  IAddMeetingRequest, IAddMeetingResponse, IDeleteMeetingResponse,
  IEditMeetingRequest,
  IEditMeetingResponse, ILinkMeetingResponse, IMeetingAccountsResponse,
  IMeetingsResponse
} from "../../model/Meetings/Meetings.model";
import {IConclusionEditResponse, IConclusionResponse} from "../../pages/Eсg/models/Conclusion.model";

export interface LoginRequest {
  email: string;
  password: string
}

export interface ResetPasswordRequest {
  email: string
}

export interface RestorePasswordRequest {
  code: string,
  password: string
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface OrganizationDto {
  id: number;
  idExt: string;
  email: string | null
  address: string | null;
  created: string;
  level: number;
  license: string;
  name: string;
  parent: {
    id: number;
    address: string;
    created: string;
    email: string;
    idExt: string;
    license: string;
    name: string;
    phone: string;
  }
  parentId: number | null;
  phone: string | null;
  children?: OrganizationDto[]
  status: string
}

export interface AddOrganizationDto {
  idExt: string | null;
  email: string | null
  address: string | null;
  license: string | null;
  name: string | null;
  parentId: number | null;
  phone: string | null;
  status: string | null;
}

export interface OrganizationsResponse {
  '@odata.count': number;
  value: OrganizationDto[]
}

export interface editOrganizationsResponse extends OrganizationDto {
  '@odata.context': string;
}

export interface DiagnosisDto {
  id: number;
  code: string;
  description: string;
}

export interface NotificationsDto {
  id: number;
  created: string;
  isRead: boolean;
  message: string;
  recipientId: number;
  title: string;
}

export interface AddDiagnosisDto {
  code: string;
  description: string;
}

export interface DiagnosisResponse {
  "@odata.count": number;
  value: DiagnosisDto[];
}

export interface NotificationsResponse {
  "@odata.count": number;
  value: NotificationsDto[];
}

export interface editDiagnosisResponse extends DiagnosisDto {
  "@odata.context": string;
}

export interface AddUserDto {
  password: string
}

export interface editUserResponse extends UserDto {
  "@odata.context": string;
}

export interface addUserResponse extends UserDto, AddUserDto {
  "@odata.context": string;
}

export interface UserAllMeasurementsDto {
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  birthDate: string;
  orgId: number;
}

export interface AllMeasurementsDto {
  id: number;
  created: string;
  userId: number;
  type: string;
  source: string;
  value: string;
  extraData?: string;
  parameters: ParametersDto[],
  needInspection: boolean;
  escalated: boolean;
  commentsNumber: number;
  norm: string;
  trackingInfo: TrackingInfoDto[];
  user: UserAllMeasurementsDto;
}

export interface TrackingInfoDto {
  organizationId: number;
  organizationLevel: number;
  organizationName: string;
  visited: boolean;
}

export interface MeasurementsResponse {
  "@odata.count": number;
  value: AllMeasurementsDto[];
}

export interface CommentDto {
  id: number;
  created: string;
  value: string;
  user: {
    id: number;
    orgId: number;
  };
  measurementId: number;
  updated: any;
}

export interface CommentResponse {
  "@odata.context": string;
  value: CommentDto[];
}

export interface FilterRequests {
  orderBy?: string[],
  top?: number,
  skip?: number,
  filter?: any,
  search?: any
}

export interface EditUserRequest {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: string | null;
  height?: number | null;
  weight?: number | null;
  phone?: string | null;
  orgId?: number | null;
  role?: string | null;
  username?: string;
}

export interface UserDto {
  avatar: number | null,
  birthDate: string;
  email: string;
  firstName: string;
  height: number | null;
  id: number;
  lastName: string;
  middleName: string;
  orgId: number | null;
  phone: string;
  role: string;
  sex: string;
  snils: string;
  status: string;
  username: string;
  commentsNumber: number;
  weight: number | null;
  diagnoses: DiagnosisDto[];
  parameters: ParametersDto[];
  measurementsParameters: {
    hasUrgentInspection: boolean;
    lastMeasurementDate: string;
    measurementTypes: MeasurementTypes[];
  }
  medParameters: {
    lowPressure: number | null;
    topPressure: number | null;
    corporateHealth: boolean;
  }
}

export interface MeasurementTypes{
  measurementType: string
  lastMeasurementNorm: string
}

export interface GetUserResponse {
  "@odata.count": number;
  value: UserDto[]

}

export interface ParametersDto {
  type: string;
  value: string;
}

export interface UrgentInspectionResponse {
  id: number;
  commentsNumber: number;
  created: string;
  userId: number;
  type: string;
  source: string;
  value: string;
  needInspection: boolean;
  escalated: boolean;
  norm: number;
  parameters: ParametersDto[];
  user: UserAllMeasurementsDto;
}

export interface LimitsDto {
  min: number;
  max: number;
  measurementType: string;
  userId?: number;
  initiatorId?: number;
}

export interface GetUserLimits {
  value: LimitsDto[]
}

export interface GetSubscribersDto {
  id: number,
  created: string,
  publisherId: number,
  subscriberId: number,
  eventType: string
}

export interface GetSubscribersResponse {
  value: GetSubscribersDto[]
}

export interface ResponseParametersResponse {
  value: ParametersDto[]
}

export abstract class Telemed<O = unknown> {
  abstract setUserContext(context: ShortAccount | null): void;

  public context: ShortAccount | null | undefined;

  abstract updateParams(authService: AuthService, notifications: INotifier): void;

  protected abstract request<T>(method: string, url: string, headers: Record<string, string | null>, options?: O, requestBody?: any): Observable<T>;

  login(data: LoginRequest) {
    const headers = {'Accept': '*/*', 'Content-Type': 'application/json',};
    return this.request<ShortAccount>('POST', '/v4/Users/Login', headers, undefined, data);
  }

  logout() {
    const headers = {'Accept': '*/*', 'Content-Type': 'application/json',};
    return this.request<ShortAccount>('POST', '/v4/Users/Logout', headers, undefined);
  }

  sendRestoreCode(data: ResetPasswordRequest) {
    const headers = {'Accept': '*/*', 'Content-Type': 'application/json',};
    return this.request<ShortAccount>('POST', '/v4/Users/SendRestoreCode', headers, undefined, data);
  }

  sendRestorePassword(data: RestorePasswordRequest){
    const headers = {'Accept': '*/*', 'Content-Type': 'application/json',};
    return this.request<ShortAccount>('POST', '/v4/Users/RestorePassword', headers, undefined, data);
  }

  sendChangePassword(data: ChangePasswordRequest) {
    const headers = {'Accept': '*/*', 'Content-Type': 'application/json',};
    return this.request<ShortAccount>('POST', '/v4/Users/ChangePassword', headers, undefined, data);
  }

  getOrganizations(data?: any): Observable<OrganizationsResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    return this.request<OrganizationsResponse>('GET', '/v4/Organizations' + buildQuery({
      count,
      ...data
    }), headers);
  }

  getChildrenOrganizations(id: number, data?: any): Observable<OrganizationsResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    return this.request<OrganizationsResponse>('GET', `/v4/Organizations(${id})/GetChildren` + buildQuery({count,...data}), headers);
  }

  editOrganization(id: number, data?: any, rqOptions?: O): Observable<editOrganizationsResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editOrganizationsResponse>('PATCH', `/v4/Organizations(${id})`, headers, rqOptions, data);
  }

  addOrganization(data: AddOrganizationDto, rqOptions?: O): Observable<editOrganizationsResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editOrganizationsResponse>('POST', `/v4/Organizations`, headers, rqOptions, data);
  }

  getDiagnosis(data?: any): Observable<DiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    return this.request<DiagnosisResponse>("GET", "/v4/Diagnoses" + buildQuery({
      count,
      ...data,
    }), headers);
  }

  getNotifications(id: number | undefined, data?: any): Observable<NotificationsResponse> {
    const headers = {Accept: "*/*"};
    let count = true;
    return this.request<NotificationsResponse>("GET", `/v4/Users(${id})/Notifications` + buildQuery({
      count,
      ...data,
    }), headers);
  }

  editNotifications(id: number | undefined): Observable<NotificationsDto[]> {
    const headers = {'Accept': '*/*',};
    return this.request<NotificationsDto[]>('POST', `/v4/Users(${id})/Notifications/MarkAllAsRead`, headers);
  }

  deleteNotifications(id: number | undefined): Observable<NotificationsDto[]> {
    const headers = {'Accept': '*/*',};
    return this.request<NotificationsDto[]>('DELETE', `/v4/Users(${id})/Notifications`, headers);
  }

  editDiagnosis(id: number, data?: AddDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editDiagnosisResponse>('PATCH', `/v4/Diagnoses(${id})`, headers, rqOptions, data);
  }

  addDiagnosis(data: AddDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editDiagnosisResponse>('POST', `/v4/Diagnoses`, headers, rqOptions, data);
  }

  deleteDiagnosis(id: number, rqOptions?: O): Observable<editDiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editDiagnosisResponse>('DELETE', `/v4/Diagnoses(${id})`, headers, rqOptions);
  }

  getAllMeasurements(data?: any): Observable<MeasurementsResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    return this.request<MeasurementsResponse>('GET', '/v4/Measurements' + buildQuery({
      count,
      ...data
    }), headers);
  }

  addMeasurementsComment(id: number, value: string, rqOptions?: O): Observable<editDiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editDiagnosisResponse>('POST', `/v4/Measurements(${id})/Comments`, headers, rqOptions, {value});
  }

  deleteMeasurementsComment(id: number, idComment: number, rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('DELETE', `/v4/Measurements(${id})/Comments(${idComment})`, headers, rqOptions);
  }

  editMeasurementsComment(id: number, idComment: number, data?: any, rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('PATCH', `/v4/Measurements(${id})/Comments(${idComment})`, headers, rqOptions, data);
  }

  getAllMeasurementsComments(id: number, data?: any): Observable<CommentResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    let orderBy = ['id desc'];
    return this.request<CommentResponse>('GET', `/v4/Measurements(${id})/Comments` + buildQuery({
      count,
      orderBy,
      ...data
    }), headers);
  }

  addUserComment(id: number, value: string, rqOptions?: O): Observable<editDiagnosisResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editDiagnosisResponse>('POST', `/v4/Users(${id})/Comments`, headers, rqOptions, {value});
  }

  deleteUserComment(id: number, idComment: number, rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('DELETE', `/v4/Users(${id})/Comments(${idComment})`, headers, rqOptions);
  }

  editUserComment(id: number, idComment: number, data?: any, rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('PATCH', `/v4/Users(${id})/Comments(${idComment})`, headers, rqOptions, data);
  }

  getUserComments(id: number, data?: any): Observable<CommentResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    let orderBy = ['id desc'];
    return this.request<CommentResponse>('GET', `/v4/Users(${id})/Comments` + buildQuery({
      count,
      orderBy,
      ...data
    }), headers);
  }

  getStatistics(data?: any): Observable<Blob> {
    const headers = {'Accept': '*/*',};
    return this.request('GET', `/v4/Reports/GetStatisticsReportShort(dateFrom=${data.dateFrom},dateTo=${data.dateTo})`, headers);
  }

  getStatisticsWithDecoding(data?: any): Observable<AjaxResponse<Blob>> {
    const headers = {'Accept': '*/*',};
    return this.request('GET', `/v4/Reports/GetStatisticsReportFull` + buildQuery({
      ...data
    }), headers);

  }

  getUsers(data?: any): Observable<GetUserResponse> {
    const headers = {'Accept': '*/*',};
    let count = true;
    return this.request<GetUserResponse>('GET', `/v4/Users` + buildQuery({
      count,
      ...data
    }), headers);
  }

  editUser(id: number, data?: EditUserRequest, rqOptions?: O): Observable<editUserResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<editUserResponse>('PATCH', `/v4/Users(${id})`, headers, rqOptions, data);
  }

  addUser(data?: EditUserRequest, rqOptions?: O): Observable<addUserResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<addUserResponse>('POST', `/v4/Users/Register`, headers, rqOptions, data);
  }

  getUser(id: string, rqOptions?: O): Observable<UserDto> {
    const headers = {'Accept': '*/*',};
    return this.request<UserDto>('GET', `/v4/Users(${id})`, headers, rqOptions);
  }

  setUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
    const headers = {'Accept': '*/*'};
    return this.request<UrgentInspectionResponse>(
      'POST',
      `/v4/Measurements(${measurementId})/SetUrgentInspection`,
      headers,
    );
  }

  unsetUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
    const headers = {'Accept': '*/*'};
    return this.request<UrgentInspectionResponse>(
      'POST',
      `/v4/Measurements(${measurementId})/UnsetUrgentInspection`,
      headers,
    );
  }

  unSetEscalated(measurementId: number,rqOptions?: O): Observable<AjaxResponse<any>> {
    const headers = {'Accept': '*/*'};
    return this.request<AjaxResponse<any>>(
      'POST',
      `/v4/Measurements(${measurementId})/ResetEscalation`,
      headers,
      rqOptions
    );
  }

  setEscalated(measurementId: number,rqOptions?: O): Observable<AjaxResponse<any>> {
    const headers = {'Accept': '*/*'};
    return this.request<AjaxResponse<any>>(
      'POST',
      `/v4/Measurements(${measurementId})/SetEscalation`,
      headers,
      rqOptions
    );
  }

  getLimits(rqOptions?: O): Observable<GetUserLimits> {
    const headers = {'Accept': '*/*',};
    return this.request<GetUserLimits>('GET', `/v4/Limits`, headers, rqOptions);
  }

  getEcg(id: string, stab: boolean, g35: boolean, g50: boolean, g75: boolean, rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('GET', `/v4/Measurements(${id})/GetEcg(comp=${stab},hp1=true,bs50=${g50},lp35=${g35},lp75=${g75})`, headers, rqOptions);
  }

  getUserLimits(id: number, rqOptions?: O): Observable<GetUserLimits> {
    const headers = {'Accept': '*/*',};
    return this.request<GetUserLimits>('GET', `/v4/Users(${id})/Limits`, headers, rqOptions);
  }

  setUserLimits(id: number, data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
    const headers = {'Accept': '*/*',};
    return this.request<GetUserLimits>('POST', `/v4/Users(${id})/Limits`, headers, rqOptions, data);
  }

  setRefLimits(data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
    const headers = {'Accept': '*/*',};
    return this.request<GetUserLimits>('POST', `/v4/Limits`, headers, rqOptions, data);
  }

  userVisit(id: string, rqOptions?: O): Observable<GetUserLimits> {
    const headers = {'Accept': '*/*',};
    return this.request<GetUserLimits>('POST', `/v4/Users(${id})/Visit`, headers, rqOptions);
  }

  getSubscribers(subscriberId: number, publisherId: number, rqOptions?: O): Observable<GetSubscribersResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<GetSubscribersResponse>('GET', `/v4/Users(${subscriberId})/Subscriptions/GetByPublisher(publisherId=${publisherId})`, headers, rqOptions);
  }

  setSubscribers(subscriberId: number, publisherId: number, data: { eventType: string }[], rqOptions?: O): Observable<GetSubscribersResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<GetSubscribersResponse>('POST', `/v4/Users(${subscriberId})/Subscriptions/Subscribe(publisherId=${publisherId})`, headers, rqOptions, data);
  }

  setParameters(userId: number, data: ParametersDto[], rqOptions?: O): Observable<ResponseParametersResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<ResponseParametersResponse>('POST', `/v4/Users(${userId})/Parameters`, headers, rqOptions, data);
  }

  unSubscribers(subscriberId: number, publisherId: number, data: { eventType: string }[], rqOptions?: O): Observable<GetSubscribersResponse> {
    const headers = {'Accept': '*/*',};
    return this.request<GetSubscribersResponse>('POST', `/v4/Users(${subscriberId})/Subscriptions/Unsubscribe(publisherId=${publisherId})`, headers, rqOptions, data);
  }

  setUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('POST', `/v4/Users(${userId})/Diagnoses`, headers, rqOptions, data);
  }

  unSetUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
    const headers = {'Accept': '*/*',};
    return this.request<any>('DELETE', `/v4/Users(${userId})/Diagnoses`, headers, rqOptions, data);
  }


  getMeetings(
    userId: number | undefined,
    data?: any,
    rqOptions?: O
  ): Observable<IMeetingsResponse> {
    const headers = { Accept: '*/*' }
    const count = true
    return this.request<IMeetingsResponse>(
      'GET',
      `/v4/Me/Meetings` +
      buildQuery({
        count,
        ...data
      }),
      headers,
      rqOptions,
      data
    )
  }

  editMeeting(
    id: number | null,
    data?: IEditMeetingRequest,
    rqOptions?: O
  ): Observable<IEditMeetingResponse> {
    const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
    return this.request<IEditMeetingResponse>(
      'PATCH',
      `/v4/Me/Meetings(${id})`,
      headers,
      rqOptions,
      data
    )
  }

  addMeeting(
    data?: IAddMeetingRequest,
    rqOptions?: O
  ): Observable<IAddMeetingResponse> {
    const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
    return this.request<IAddMeetingResponse>(
      'POST',
      `/v4/Me/Meetings`,
      headers,
      rqOptions,
      data
    )
  }

  deleteMeeting(id: number, rqOptions?: O): Observable<IDeleteMeetingResponse> {
    const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
    return this.request<IDeleteMeetingResponse>(
      'DELETE',
      `/v4/Me/Meetings(${id})`,
      headers,
      rqOptions
    )
  }

  getInvitedUsers(data?: any): Observable<IMeetingAccountsResponse> {
    const headers = { Accept: '*/*' }
    const count = true
    return this.request<IMeetingAccountsResponse>(
      'GET',
      `/v4/Accounts` +
      buildQuery({
        count,
        ...data
      }),
      headers,
      undefined,
      data
    )
  }

  getLinkMeeting(meetingId: number | undefined, data?: any): Observable<ILinkMeetingResponse> {
    const headers = { Accept: '*/*' }
    return this.request<ILinkMeetingResponse>(
      'GET',
      `/v4/Me/Meetings(${meetingId})/GetLink` +
      buildQuery({
        ...data
      }),
      headers,
      undefined,
      data
    )
  }


  addMeasurementsConclusion(
    id: number,
    value: string
  ): Observable<IConclusionEditResponse> {
    const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
    return this.request<IConclusionEditResponse>(
      'POST',
      `/v4/Measurements(${id})/Conclusions`,
      headers,
      undefined,
      { value }
    )
  }

  getConclusions(id: number, data?: any): Observable<IConclusionResponse> {
    const headers = { Accept: '*/*' }
    const count = true
    const orderBy = ['id desc']
    return this.request<IConclusionResponse>(
      'GET',
      `/v4/Measurements(${id})/Conclusions` +
      buildQuery({
        count,
        orderBy,
        ...data
      }),
      headers
    )
  }

  editMeasurementsConclusion(
    id: number,
    idConclusion: number,
    data?: any,
  ): Observable<IConclusionEditResponse> {
    const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
    return this.request<IConclusionEditResponse>(
      'PATCH',
      `/v4/Measurements(${id})/Conclusions(${idConclusion})`,
      headers,
      undefined,
      data
    )
  }
}