import {makeObservable} from 'mobx';
import {Observable, of, throwError} from 'rxjs';
import {AjaxConfig, AjaxResponse} from 'rxjs/ajax';
import {catchError, map, mergeMap, take, tap} from 'rxjs/operators';
import {Telemed} from '../conectors/telemed';
import {fetch$} from '../utils';
import {ShortAccount} from "../model/short-account";
import {INotifier} from "../providers/notifications/notifications.context";
import {AuthService} from "./auth-service";

export interface RequestOptions extends Partial<AjaxConfig> {
  authContext?: ShortAccount;
  isReq?: boolean;
}

export class TelemedError extends Error {

  constructor(public readonly response: any) {
    super(response.error.message);
  }
}

type TRequest = {
  requestParams: AjaxConfig;
  context: ShortAccount | null;
}

export class TelemedService extends Telemed<RequestOptions> {

  protected baseUrl = '/api';
  public context: ShortAccount | null = null;
  public authService: AuthService | null = null;
  public notification: INotifier | null = null;

  constructor() {
    super();

    makeObservable(this, {})
    this.makeRequest = this.makeRequest.bind(this);
  }

  updateParams(authService: AuthService, notification: INotifier) {
    this.authService = authService;
    this.notification = notification;
  }

  setUserContext(context: ShortAccount | null) {
    this.context = context;
  }

  protected request<T>(method: string, url: string, headers?: Record<string, string | null>, options?: RequestOptions, requestBody?: unknown): Observable<T> {
    return new Observable<TRequest>(observer => {
      if (!headers) {
        headers = {} as Record<string, string>;
      }
      headers['Telemed-Client-Base'] = window.location.origin + process.env.PUBLIC_URL;

      let context = options?.authContext || this.context;

      if (context) {
        headers['Authorization'] = 'Bearer ' + context.accessToken;
      }

      let requestParams = {
        method,
        headers,
        url: this.baseUrl + url,
        body: requestBody
      };

      observer.next({context, requestParams});
      observer.complete();
    })
      .pipe(
        take(1),
        mergeMap(conf => {
          return of(conf);
        }),
        mergeMap(this.makeRequest),
        map(resp => {
          if(options?.isReq){
            return resp
          }

          if (resp.responseHeaders['content-type'] && resp.responseHeaders['content-type'].includes('zip')) {
            return resp
          }

          return resp.response
        })
      )
  }

  protected makeRequest({context, requestParams}: TRequest): Observable<AjaxResponse<any>> {
    return fetch$(requestParams)
      .pipe(
        tap(resp => {
          if (![200, 201, undefined].includes(resp.status)) {
            throw resp;
          }
        }),
        catchError((err: AjaxResponse<any>) => {
          if (err.status === 401 && context !== null) {
            if (this.authService) {
              this.authService.logout(context, true)
              this.notification?.warn('Сессия закончилась')
            }
          }
          let _err: Error;
          _err = new TelemedError(err.response);
          return throwError(() => _err);
        }),
      )
  }
}
