import {makeObservable, observable} from 'mobx';
import {OrganizationDto} from "../conectors/telemed";

export interface IAccountCompanyInfo {
  id: string;
  name: string;
}

export enum AccountType {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  AdminSupport = 'admin-support',
  Doctor = 'doctor',
  MedWorker = 'medworker',
  Patient = 'patient'
}

export class ShortAccount {

  static fromObject(obj: ShortAccount) {
    return makeObservable(Object.setPrototypeOf(obj, this), {
      userId: observable,
      role: observable,
      email: observable,
      firstName: observable,
      middleName: observable,
      lastName: observable,
      orgId: observable,
      organization: observable,
      avatar: observable,
      accessToken: observable,
    });
  }

  private constructor(public readonly userId: number,
                      public role: AccountType,
                      public email: string,
                      public firstName: string,
                      public orgId: number,
                      public organization: OrganizationDto | undefined,
                      public middleName: string,
                      public lastName: string,
                      public avatar: string | null,
                      public accessToken: string) {
  }
}

