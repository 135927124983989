import {Observable} from 'rxjs';

export function fromIdb<T>(req: IDBRequest<T>): Observable<T> {
  return new Observable(observer => {
    req.onsuccess = () => {
      observer.next(req.result);

      if (req.readyState === 'done') {
        observer.complete();
      }
    }

    req.onerror = () => {
      observer.error(req.error);
    }
  });
}

export function fromIdbCursor<V>(transaction: IDBTransaction, store: IDBObjectStore): Observable<[string, V][]> {
  return new Observable(observer => {
    let value: [string, V][] = [];

    store.openCursor().onsuccess = (event) => {
      let cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {
        value.push([cursor.key as string, cursor.value as V]);
        cursor.continue();
      }
    }

    transaction.onerror = (ev) => {
      observer.error((ev.target as IDBTransaction).error);
    }

    transaction.oncomplete = () => {
      observer.next(value);
      observer.complete();
    }
  });
}
