import {Telemed} from '../conectors/telemed';
import {ShortAccount} from "../model/short-account";

export function accountServiceFactory(telemed: Telemed) {
  return (account: ShortAccount) => new AccountService(telemed, account);
}

export type TAccountServiceFactory = ReturnType<typeof accountServiceFactory>;

export class AccountService {
  constructor(protected telemed: Telemed, protected account: ShortAccount) {
  }
}
