import {forkJoin, Observable} from 'rxjs';
import {Telemed} from '../../conectors/telemed';
import {TelemedService} from '../../services';
import {asyncResolver} from "../../utils/async-component";
import {getApplicationStorage, TIApplicationStore} from "../../services/local-store";
import {map, mergeMap} from "rxjs/operators";
import {AuthService} from "../../services/auth-service";
import {BootProps} from "./boot";
import {Config, ConfigStore, getConfig} from "../../services/config";

const telemed = new TelemedService();

interface IInitialData {
    applicationStore: TIApplicationStore;
    config: Config;
}

interface IAccountsData {
  authService: AuthService;
}

function loadInitialData(telemed: Telemed) {
    return forkJoin({
        applicationStore: getApplicationStorage(),
        config: getConfig(),
    })
}

function loadAccounts(initial: IInitialData) {
  return initial.applicationStore.getAll('accounts')
    .pipe(
      map(accounts => new AuthService(telemed, initial.applicationStore, accounts, accounts[0] ?? null)),
      map(authService => ({...initial, authService}))
    )
}

function prepareConfiguration(initial: IInitialData & IAccountsData): BootProps {
    let {applicationStore, authService, config} = initial;

    return {
        applicationStore,
        telemed,
        authService,
        configStore: new ConfigStore(config,authService)
    }
}

function bootPropsResolver(telemed: Telemed): Observable<BootProps> {
  return loadInitialData(telemed)
    .pipe(
      mergeMap(loadAccounts),
      map(prepareConfiguration)
    )
}


export default asyncResolver(() => import('./boot'), bootPropsResolver(telemed));
