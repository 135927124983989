import {configure, when} from 'mobx';
import {JSXElementConstructor} from 'react';
import './index.css';
import Boot from './states/boot/boot.lazy';
import {GlobalState} from './global';
import {RootNodeContext} from './hooks/useRootNode';
import {throwNull} from "./utils/typecheck";
import {useOnMount} from "./hooks/useOnMount";
import {createRoot} from "react-dom/client";
import moment from "moment/moment";

configure({enforceActions: 'never'});

const container = throwNull(document.getElementById('root'), 'Root node not found');
const preloader = document.getElementById('preloader');
const root = createRoot(container);

moment.updateLocale('en', {
  months: [
    "Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля",
    "Августа", "Сентября", "Октября", "Ноября", "Декабря"
  ]
});

function Application({BaseComponent}: { BaseComponent: JSXElementConstructor<any> }) {
  useOnMount(() => {
    when(
      () => !GlobalState.waitingFirstState.value,
      () => {
        preloader && preloader.remove();
        container.style.display = '';
      }
    );
  });

  return <RootNodeContext.Provider value={container}>
    <BaseComponent/>
  </RootNodeContext.Provider>
}

Boot.subscribe((BaseComponent: JSXElementConstructor<any>) => {
  root.render(<Application BaseComponent={BaseComponent}/>);
});